import React from 'react';
import { Link } from 'gatsby';
import ctaBg from '../../../common/assets/image/interior/bg/feature-bg.jpg';
import SectionWrapper, {
  Container
} from './calltoaction.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone  } from '@fortawesome/free-solid-svg-icons';

import Fade from 'react-reveal/Fade';

const CallToAction = () => {
  
  return (
    <SectionWrapper id="callToAction2">
      <Container className='container'>
        <Fade bottom>
        <div className='ctaBox'>
          <h3>Book Your Appointment Online <br />and Call Our Studio</h3>
          <div className='btnWrap'>
            <a href="tel:1800-103-1191" className='callbtn'><FontAwesomeIcon icon={faPhone } /> +91-99585-85649</a>
            <Link to="/appointment.php" className="btn btn-default black">Booking Appoitment</Link>
          </div>
        
        </div>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default CallToAction;
